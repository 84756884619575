.round-select .ant-select-selector {
  border-radius: 10px !important;
  font-size: 18px;
  border: 1px solid #727272 !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.049),
    0px 0px 5.6px rgba(0, 0, 0, 0.07), 0px 0px 13.6px rgba(0, 0, 0, 0.091),
    0px 0px 45px rgba(0, 0, 0, 0.14) !important;
  height: 45px !important;
}

.round-select .ant-select-selection-placeholder {
  line-height: 45px !important;
}

.round-select input {
  height: 45px !important;
  line-height: 45px !important;
}

.round-select .ant-select-selection-item {
  line-height: 45px !important;
}

.transparent-button {
  background-color: rgba(0, 0, 0, 0.2);
  border: none !important;
}

.ant-statistic-content {
  display: flex;
  flex-direction: row;
}

.custom-card {
  border-radius: 10px !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.049),
    0px 0px 5.6px rgba(0, 0, 0, 0.07), 0px 0px 13.6px rgba(0, 0, 0, 0.091),
    0px 0px 45px rgba(0, 0, 0, 0.14);
}

.ant-menu-item-selected {
  background-color: rgb(0, 119, 199) !important;
}